import styled from 'styles/styled';

import { Heading } from 'components/ui/Heading';
import { Button } from 'components/ui/Button';

export const Wrapper = styled.div`
  margin-bottom: 150px;
  display: flex;
  align-items: center;
  flex-direction: column;

  ${Heading} {
    align-self: center;
  }
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.palette.light};
  font-size: ${({ theme }) => theme.fontSize.titleMobile};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin: ${({ theme }) => theme.dimensions.spacingBase10}px 0;

  @media ${({ theme }) => theme.devices.medium} {
    font-size: ${({ theme }) => theme.fontSize.title};
  }
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.palette.lightDark};
  font-size: ${({ theme }) => theme.fontSize.baseMobile};
  line-height: normal;
  letter-spacing: 0.14px;
  margin: 0 35px;
  width: 250px;
  text-align: center;

  @media ${({ theme }) => theme.devices.medium} {
    font-size: ${({ theme }) => theme.fontSize.base};
    width: 310px;
  }
`;

export const GoBack = styled(Button)`
  text-transform: uppercase;
  margin-top: ${({ theme }) => theme.dimensions.spacingBase10 * 4}px;

  @media ${({ theme }) => theme.devices.medium} {
    margin-top: ${({ theme }) => theme.dimensions.spacingBase10 * 3}px;
  }
`;
