import React from 'react';
import { navigate } from '@reach/router';

import { Heading } from 'components/ui/Heading';

import { Wrapper, Title, Text, GoBack } from './styled';

interface Props {
  error: string;
  title?: string;
  text?: string;
  goBack: string;
}

const ErrorPage = ({ error, title, text, goBack }: Props) => {
  return (
    <Wrapper>
      <Heading>{error}</Heading>
      {title && <Title>{title}</Title>}
      {text && <Text>{text}</Text>}
      <GoBack onClick={() => navigate(-1)}>{goBack}</GoBack>
    </Wrapper>
  );
};

export default ErrorPage;
